import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

// Amplify
import { Hub } from 'aws-amplify'
// @ts-expect-error
import { HubPayload } from '@aws-amplify/core/lib/Hub'

import SignInForm from './components/SignInForm'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'
import RequireNewPassword from './components/RequireNewPassword'
import VerifyMFA from './components/VerifyMFA'
import LanguageSelector from '../../../../common/components/LanguageSelector'
import onLogo from 'src/assets/images/onLogo.svg'
import candelicLogo from 'src/assets/images/candelicLogo.svg'
import candelicLoader from 'src/assets/images/candelicLoader.gif'
import { Icon, ProgressBar } from '@blueprintjs/core'
import Button from 'reactstrap/lib/Button'
import views from 'src/config/views'

@inject('store')
@observer
class SignIn extends React.Component<{ store?: RootStore } & WithNamespaces> {
    componentDidMount() {
        // Initialise Hub listener
        Hub.listen('auth', data => {
            const { payload } = data
            this.onAuthEvent(payload)
        })
    }

    onAuthEvent(payload: HubPayload) {
        switch (payload.event) {
            case 'signIn':
                this.props.store!.authStore.authenticate()
                break
        }
    }

    render() {
        return (
            <div className='auth-container'>
                {!this.props.store!.userStore.me && !this.props.store!.authStore.isFetching && (
                    <React.Fragment>
                        <div className='auth-language-selector d-none d-md-block'>
                            <LanguageSelector />
                        </div>
                        <div className='auth-sign-in'>
                            <div className='auth-mobile-header d-md-none'>
                                <img className='auth-mobile-logo' src={onLogo} alt='ON Logo' />
                                <div className='auth-language-selector-mobile'>
                                    <LanguageSelector />
                                </div>
                            </div>
                            <div className='auth-leftside-bar d-none d-md-flex'>
                                <div className='auth-leftside-bar-top'>
                                    <img src={onLogo} alt='ON Logo' />
                                    <div className='auth-leftside-bar-text'>
                                        <span>{i18n.t('authPage.authLead')}</span>
                                    </div>
                                </div>
                                <div className='auth-leftside-bar-bottom text-center'>
                                    <div className='auth-leftside-bar-smalltext'>
                                        <span>{i18n.t('common.by')}</span>
                                    </div>
                                    <a href='https://candelic.com' target='_blank' rel='noopener noreferrer'>
                                        <img src={candelicLogo} alt='Candelic Logo' />
                                    </a>
                                </div>
                            </div>
                            <div className='auth-form-states'>
                                <div>
                                    <div className='auth-banner'>
                                        <div className='auth-banner-text'>
                                            <span>
                                                <Icon icon='warning-sign' size={15} className='mr-2' />
                                                Notice: If you are no longer able to login to your ON account, you have
                                                either signed a contract and been migrated to OutWatch, or we have not
                                                heard from you and your service is being terminated. If you would like
                                                to begin the process of migrating to OutWatch, please contact{' '}
                                                <a className='text-underline' href='mailto:sales@outwatch.io'>
                                                    sales@outwatch.io
                                                </a>
                                                . In the case believe your ON service has been terminated prematurely,
                                                please contact{' '}
                                                <a className='text-underline' href='mailto:support@outwatch.io'>
                                                    support@outwatch.io
                                                </a>
                                                .
                                            </span>
                                            <br />
                                            <br />
                                            <span>
                                                The OutWatch platform is now available at{' '}
                                                <a className='text-underline' href='https://app.outwatch.io'>
                                                    app.outwatch.io &#8594;
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <SignInForm />
                                    <ForgotPassword />
                                    <ResetPassword />
                                    <RequireNewPassword />
                                    <VerifyMFA />
                                    <hr />
                                    <div className='auth-links'>
                                        <Button
                                            className='custom-button-link'
                                            color='link'
                                            onClick={() => this.props.store!.router.goTo(views.privacyPolicy)}
                                        >
                                            <span>{i18n.t('authPage.privacyPolicy')}</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {this.props.store!.authStore.isFetching && (
                    <div className='auth-loading'>
                        <img src={candelicLoader} alt='Loading' height={58} />
                        <ProgressBar
                            className='auth-progress-bar bp3-no-stripes'
                            value={this.props.store!.authStore.loadingValue}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default withNamespaces()(SignIn)
